import getQueryUtils from '@wix/santa-main-r/lib/lib/common/getQueryUtils'
// @ts-ignore
import PackagesUtil from '@wix/santa-main-r/lib/lib/common/PackagesUtil'
// @ts-ignore
import getRjsConfig from '@wix/santa-main-r/lib/lib/viewer/get-rjs-config'
// @ts-ignore
import experimentFactory from 'santa-main-r/lib/lib/common/experimentFactory'
import santaOptions from '@wix/wix-santa/dist/options.json'
import pkgs from '@wix/wix-santa/dist/packagesList.json'
import santaPackage from '@wix/wix-santa/package.json'
import wixUIPackage from '@wix/wix-ui-santa/package.json'
import {checkConfig} from './checkConfig'
import {loadScript} from './utils'

const {version: santaVersion} = santaPackage
const {version: wixUiVersion} = wixUIPackage

export function genUnpkgUrl(packageName: string, version: string, path: string): string {
    return `https://static.parastorage.com/unpkg/${packageName}@${version}/${path}`
}

const loadRequireJs = async () => {
    await loadScript('https://static.parastorage.com/services/third-party/requirejs/2.1.15/require.min.js', 'requirejs')
}

interface RjsPkg {
    location: string
}

declare global {
    interface Window {
        define(name: string, cb: Function): void
        requirejs: {
            config(s: any): void
            (deps: string[], cb: Function): void
        }
        React: any
        ReactDOM: any
        pmrpc: any
        _: any
        queryUtils: any
    }
}

const santaUnpkg = (p: string) => genUnpkgUrl('@wix/wix-santa', santaVersion, p)

const fixSantaPaths = (paths: Record<string, string>, packages: RjsPkg[]) => {
    pkgs.forEach(p => {
        const orgPath = paths[p]
        if (orgPath) {
            paths[p] = santaUnpkg(orgPath)
        }
    })
    packages.forEach((p: RjsPkg) => {
        //p.location = `https://localhost:8080/node_modules/@wix/wix-santa/${p.location}`
        p.location = santaUnpkg(p.location)
    })
    const fixedPaths = checkConfig(paths)
    return fixedPaths
}

const defineExperimentInstance = () => {
    const experiments: string = window.queryUtils.getParameterByName('experiments')
    experiments.split(',').forEach(name => {
        window.rendererModel.runningExperiments[name] = 'new'
    })
    const experimentInst = experimentFactory.build(window)
    window.define('experiment', () => experimentInst)
}

const main = async () => {
    await loadRequireJs()
    const queryUtils = getQueryUtils(window)
    window.queryUtils = queryUtils
    const isDebug = !!queryUtils.getParameterByName('debug')
    defineExperimentInstance()
    const rjsConfig = getRjsConfig(santaOptions.versions, window.serviceTopology, false, {})
    rjsConfig.paths.mainScript = `${window.dmBase}/tb-main/dist/tb-main-internal${isDebug ? '' : '.min'}`
    const packagesUtil = new PackagesUtil(pkgs, window.location.search)
    const {paths, packages, map, shim, bundles} = packagesUtil.buildConfig(rjsConfig)
    paths.dsComponentsService = genUnpkgUrl('@wix/wix-ui-santa', wixUiVersion, 'dsComponentsService')
    paths.editorSkinsData = santaUnpkg('dist/editorSkinsData')
    const fixedPaths = fixSantaPaths(paths, packages)
    map['*']['pm-rpc'] = 'pmrpc'
    window.requirejs.config({
        paths: fixedPaths,
        packages,
        map,
        shim,
        waitSeconds: 60,
        bundles
    })
    window.requirejs(['react', 'react-dom', 'lodash', 'pmrpc'], (react: any, reactDom: any, lodash: any, pmrpc: any) => {
        window.React = react
        window.ReactDOM = reactDom
        window._ = lodash
        window.pmrpc = pmrpc
        window.requirejs(['mainScript'], () => {})
    })
}

main()
